import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FirebaseContext } from 'contexts/Firebase';
import * as request from 'request';
import memoize from 'memoize-one';
import Delete from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import ReorderOutlined from '@mui/icons-material/ReorderOutlined';
import { PageLoader, showToastAlert } from 'Services/Utility';
import Navbar from 'components/Navbar';
import Swal from 'sweetalert2';
import PoolModifyModal from 'Modals/PoolModifyModal';
import ProviderModifyModal from 'Modals/ProviderModifyModal';
import PoolOrderModal from 'Modals/PoolOrderModal'


const serverColumns = [
    {
        name: 'IP address',
        selector: row => row.ip || '',
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status || '',
        style: { textTransform: 'capitalize' },
        sortable: true,
    },
    {
        name: 'Tag',
        selector: row => row.tag || '',
        sortable: true,
    },
    {
        name: 'Open Sessions',
        selector: row => row.stats && row.stats.nOpenSessions || '0',
        sortable: true,
    }
];

const poolColumns = memoize((updateHandler, deleteHandler) => [
    {
        name: 'Name',
        selector: row => row.myCountry || row.name,
        sortable: true
    },
    {
        name: 'Continent',
        selector: row => row.continent || '',
        sortable: true
    },
    {
        name: 'Tag',
        selector: row => row.tag || '',
        sortable: true
    },
    {
        name: 'Providers',
        selector: (row) => {
            let providers = [];
            if (!row.myCountry) return '';
            if (row.dawnDomain && row.dawnDomain.includes('superproxy') || row.gangaDomain && row.gangaDomain.includes('superproxy') || row.yamunaDomain && row.yamunaDomain.includes('superproxy')) {
                providers.push('Brightdata');
            }
            if (row.dawnDomain && row.dawnDomain.includes('packet') || row.gangaDomain && row.gangaDomain.includes('packet') || row.yamunaDomain && row.yamunaDomain.includes('packet')) {
                providers.push('Packet');
            }
            if (row.dawnDomain && row.dawnDomain.includes('subnet') || row.gangaDomain && row.gangaDomain.includes('subnet') || row.yamunaDomain && row.yamunaDomain.includes('subnet')) {
                providers.push('Subnet');
            }
            if (row.dawnDomain && row.dawnDomain.includes('resi4') || row.gangaDomain && row.gangaDomain.includes('resi4') || row.yamunaDomain && row.yamunaDomain.includes('resi4')) {
                providers.push('Subnet Noc');
            }
            if (row.dawnDomain && row.dawnDomain.includes('oxylabs') || row.gangaDomain && row.gangaDomain.includes('oxylabs') || row.yamunaDomain && row.yamunaDomain.includes('oxylabs')) {
                providers.push('Oxylabs');
            }
            if (row.dawnDomain && row.dawnDomain.includes('smrt') || row.gangaDomain && row.gangaDomain.includes('smrt') || row.yamunaDomain && row.yamunaDomain.includes('smrt')) {
                providers.push('Smart-x');
            }
            if (row.dawnDomain && row.dawnDomain.includes('smartproxy') || row.gangaDomain && row.gangaDomain.includes('smartproxy') || row.yamunaDomain && row.yamunaDomain.includes('smartproxy')) {
                providers.push('Smart');
            }
            if (row.dawnDomain && row.dawnDomain.includes('ntnt') || row.gangaDomain && row.gangaDomain.includes('ntnt') || row.yamunaDomain && row.yamunaDomain.includes('ntnt')) {
                providers.push('Netnut');
            }
            if (row.dawnDomain && row.dawnDomain.includes('geosurf') || row.gangaDomain && row.gangaDomain.includes('geosurf') || row.yamunaDomain && row.yamunaDomain.includes('geosurf')) {
                providers.push('Geosurf');
            }
            if (row.dawnDomain && row.dawnDomain.includes('iproyal') || row.gangaDomain && row.gangaDomain.includes('iproyal') || row.yamunaDomain && row.yamunaDomain.includes('iproyal')) {
                providers.push('Iproyal');
            }
            if (row.dawnDomain && row.dawnDomain.includes('onestop') || row.gangaDomain && row.gangaDomain.includes('onestop') || row.yamunaDomain && row.yamunaDomain.includes('onestop')) {
                providers.push('One Stop');
            }
            return providers.join(', ');
        },
        sortable: false
    },
    {
        name: 'Action',
        cell: (row) => {
            if (!row.myCountry) return '';
            return (<><button className='icon-btn add' onClick={e => updateHandler(row.myCountry)}><UpdateIcon /></button> <button className='icon-btn delete' onClick={e => deleteHandler(row.myCountry)}><Delete /></button></>)
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);

const providerColumns = memoize((updateHandler, deleteHandler) => [
    {
        name: 'Name',
        selector: row => row.name || '',
        sortable: true
    },
    {
        name: 'Username',
        selector: row => row.username || '',
        sortable: true
    },
    {
        name: 'Password',
        selector: row => row.password || '',
        sortable: true
    },
    {
        name: 'Action',
        cell: (row) => (<><button className='icon-btn add' onClick={e => updateHandler(row.name, row.id)}><UpdateIcon /></button> <button className='icon-btn delete' onClick={e => deleteHandler(row.name, row.id)}><Delete /></button></>),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        sortable: false,
    },
]);


const Plans = (props) => {
    const { user, firebase } = useContext(FirebaseContext);
    const history = useHistory();

    const [proServers, setProServers] = useState([]);
    const [premiumServers, setPremiumServers] = useState([]);
    const [proPools, setProPools] = useState([]);
    const [premiumPools, setPremiumPools] = useState([]);
    const [providers, setProviders] = useState([]);
    const [subnetCountries, setSubnetCountries] = useState([]);
    // const [subnetNocCountries, setSubnetNocCountries] = useState([]);

    const [modifyModals, setModifyModals] = useState([]);

    const [webhookUrl, setWebhookUrl] = useState('');

    const [filterServerText, setFilterServerText] = useState('');
    const [filterPoolText, setFilterPoolText] = useState('');
    const [filterProviderText, setFilterProviderText] = useState('');

    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(true);
    const [activeTabKey, setActiveTabKey] = useState('premium');

    const tabNames = { 'premium': 'Premium', 'pro': 'Pro' };


    const filteredServers = () => {
        if (activeTabKey === 'pro') {
            return proServers.filter(x => x.ip.includes(filterServerText.toLowerCase()) || x.status.includes(filterServerText.toLowerCase()) || x.tag.includes(filterServerText.toLowerCase()))
        }
        else if (activeTabKey === 'premium') {
            return premiumServers.filter(x => x.ip.includes(filterServerText.toLowerCase()) || x.status.includes(filterServerText.toLowerCase()) || x.tag.includes(filterServerText.toLowerCase()))
        }
        else {
            return [];
        }
    }

    const filteredPools = () => {
        if (activeTabKey === 'pro') {
            if (proPools.length > 0 && proPools[0].myCountry) {
                return proPools.filter(x => x.myCountry.toLowerCase().includes(filterPoolText.toLowerCase()) || x.continent.toLowerCase().includes(filterPoolText.toLowerCase()) || x.tag.toLowerCase().includes(filterPoolText.toLowerCase()))
            }
            return proPools.filter(x => x.name.toLowerCase().includes(filterPoolText.toLowerCase()) || x.domain.toLowerCase().includes(filterPoolText.toLowerCase()) || x.continent.toLowerCase().includes(filterPoolText.toLowerCase()))
        }
        else if (activeTabKey === 'premium') {
            if (premiumPools.length > 0 && premiumPools[0].myCountry) {
                return premiumPools.filter(x => x.myCountry.toLowerCase().includes(filterPoolText.toLowerCase()) || x.continent.toLowerCase().includes(filterPoolText.toLowerCase()) || x.tag.toLowerCase().includes(filterPoolText.toLowerCase()))
            }
            return premiumPools.filter(x => x.name.toLowerCase().includes(filterPoolText.toLowerCase()) || x.domain.toLowerCase().includes(filterPoolText.toLowerCase()) || x.continent.toLowerCase().includes(filterPoolText.toLowerCase()))
        }
        else {
            return [];
        }
    }

    const filteredProvider = () => {
        return providers.filter(x => x.name.toLowerCase().includes(filterProviderText.toLowerCase()) || x.username.toLowerCase().includes(filterProviderText.toLowerCase()) || x.password.toLowerCase().includes(filterProviderText.toLowerCase()));
    }

    useEffect(() => {
        document.body.classList.add('dash-bg');
        document.body.classList.add('plan-price-bg');
    }, []);

    const getServerData = (idToken, planType) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/${planType}/getServerPool`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : `A server error occurred while fetching ${planType} server data!`;
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    return resolve(resData || {});
                }
            });
        });
    }

    const getSubnetCountries = (idToken) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/subnet/countries`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'idToken': idToken
                })
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : `A server error occurred while fetching subnet pools!`;
                    showToastAlert('Error', errorMessage);
                    resolve({});
                } else {
                    return resolve(resData || {});
                }
            });
        });
    }

    // const getSubnetNocCountries = (idToken) => {
    //     return new Promise((resolve, reject) => {
    //         let options = {
    //             method: 'POST',
    //             url: `${process.env.REACT_APP_API_URL}/subnetNoc/countries`,
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 'idToken': idToken
    //             })
    //         };
    //         return request(options, (error, response, body) => {
    //             let resData = body && JSON.parse(body);
    //             if (error || response.statusCode !== 200) {
    //                 let errorMessage = error ? error : resData ? resData.message : `A server error occurred while fetching subnet pools!`;
    //                 showToastAlert('Error', errorMessage);
    //                 resolve({});
    //             } else {
    //                 return resolve(resData || {});
    //             }
    //         });
    //     });
    // }

    const changeActiveTab = (e) => {
        const planType = e.target.getAttribute('data-key');
        setActiveTabKey(planType);
    }

    const refreshList = async () => {
        const idToken = await firebase.auth.currentUser.getIdToken(true);
        const [proData, premiumData] = await Promise.all([getServerData(idToken, 'pro'), getServerData(idToken, 'premium')]);
        setProServers(proData);
        setPremiumServers(premiumData);
    }

    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    const webhookKeyEvent = async (event) => {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13') {
            if (event.target.value.trim() === '' || isValidHttpUrl(event.target.value)) {
                await firebase.db.ref(`webhooks/url`).set(event.target.value.trim());
            }
        }
    }

    const webhookFocusEvent = async (event) => {
        if (event.target.value.trim() === '' || isValidHttpUrl(event.target.value)) {
            await firebase.db.ref(`webhooks/url`).set(event.target.value.trim());
        }
    }

    const updatePoolAPI = async (data) => {
        return new Promise((resolve, reject) => {
            let options = {
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/${activeTabKey}/createPool`,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            return request(options, (error, response, body) => {
                let resData = body && JSON.parse(body);
                if (error || response.statusCode !== 200) {
                    let errorMessage = error ? error : resData ? resData.message : 'Error occurred while deleting pool!';
                    showToastAlert('Error', errorMessage);
                    return resolve({});
                } else {
                    return resolve(resData || {});
                }
            });
        });
    }

    const handlePoolDeleteClick = (name) => {
        Swal.fire({
            html: 'Are you sure you want to delete <strong>' + name + '</strong> ?<br /><sub>You will not be able to revert this</sub>',
            icon: 'question',
            color: '#103a8d',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    const pools = activeTabKey === 'pro' ? proPools : (activeTabKey === 'premium' ? premiumPools : []);
                    let newData = {};
                    pools.forEach(x => {
                        if (x.myCountry !== name) {
                            let dict = {
                                'myCountry': x.myCountry,
                                'continent': x.continent,
                                'tag': x.tag,
                                'cc3': x.cc3,
                                'flag': x.flag,
                                'nports': x.nports,
                                'dawnDomain': x.dawnDomain || '',
                                'dawnFormat': x.dawnFormat || '',
                                'gangaDomain': x.gangaDomain || '',
                                'gangaFormat': x.gangaFormat || '',
                                'yamunaDomain': x.yamunaDomain || '',
                                'yamunaFormat': x.yamunaFormat || ''
                            }
                            if (x.cc2) {
                                dict['cc2'] = x.cc2;
                            }
                            newData[x.myCountry] = dict;
                        }
                    })
                    setLoading(true);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    const data = { idToken: idToken, pools: newData }
                    const poolsData = await updatePoolAPI(data);
                    poolModifyCallback(poolsData);
                    showToastAlert('success', 'Pool deleted successfully');
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
                setLoading(false);
            }
        });
    }

    const handleProviderDeleteClick = (name, id) => {
        Swal.fire({
            html: 'Are you sure you want to delete <strong>' + name + '</strong> ?<br /><sub>You will not be able to revert this</sub>',
            icon: 'question',
            color: '#103a8d',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            reverseButtons: true
        }).then(async (result) => {
            if (result.value) {
                try {
                    await firebase.db.ref(`providers/${id}`).set(null);
                    await providerModifyCallback();
                }
                catch (err) {
                    showToastAlert('Error', err.message ? err.message : err);
                }
            }
        });
    }

    const handlePoolUpdateClick = (name) => {
        if (providers.length == 0) {
            return showToastAlert('Error', 'Please create provider first');
        }
        const pools = activeTabKey === 'pro' ? proPools : (activeTabKey === 'premium' ? premiumPools : []);
        setModifyModals([]);
        setModifyModals([
            <PoolModifyModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                setLoading={setLoading}
                successCallback={poolModifyCallback}
                allPools={pools}
                myCountry={name}
                providers={providers}
                isUpdate={true}
                planType={activeTabKey}
                subnetCountries={subnetCountries}
            // subnetNocCountries={subnetNocCountries}
            />
        ]);
    }

    const handleProviderUpdateClick = (name, id) => {
        const data = providers.find(x => x.id === id);
        setModifyModals([]);
        if (data) {
            setModifyModals([
                <ProviderModifyModal
                    showModal={true}
                    key={Math.random()}
                    firebase={firebase}
                    setLoading={setLoading}
                    successCallback={providerModifyCallback}
                    isUpdate={true}
                    data={data}
                />
            ]);
        }
    }

    const handleAddPool = () => {
        if (providers.length == 0) {
            return showToastAlert('Error', 'Please create provider first');
        }
        const pools = activeTabKey === 'pro' ? proPools : (activeTabKey === 'premium' ? premiumPools : []);
        setModifyModals([]);
        setModifyModals([
            <PoolModifyModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                setLoading={setLoading}
                successCallback={poolModifyCallback}
                allPools={pools}
                providers={providers}
                isUpdate={false}
                planType={activeTabKey}
                subnetCountries={subnetCountries}
            // subnetNocCountries={subnetNocCountries}
            />
        ]);
    }

    const handleAddProvider = () => {
        setModifyModals([]);
        setModifyModals([
            <ProviderModifyModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                setLoading={setLoading}
                successCallback={providerModifyCallback}
                isUpdate={false}
            />
        ]);
    }

    const poolModifyCallback = (response) => {
        if (activeTabKey === 'pro') {
            setProPools(response.countries || []);
        }
        else if (activeTabKey === 'premium') {
            setPremiumPools(response.countries || []);
        }
    }

    const providerModifyCallback = async () => {
        let providers = (await firebase.db.ref(`providers`).once('value')).val();
        providers = Object.values(providers || {});
        setProviders(providers);
    }

    const handleModifyPoolOrder = () => {
        const pools = activeTabKey === 'pro' ? proPools : (activeTabKey === 'premium' ? premiumPools : []);
        if (pools.length == 0) return;

        setModifyModals([]);
        setModifyModals([
            <PoolOrderModal
                showModal={true}
                key={Math.random()}
                firebase={firebase}
                setLoading={setLoading}
                successCallback={poolModifyCallback}
                allPools={pools}
                planType={activeTabKey}
            />
        ]);
    }

    useEffect(() => {
        (async function () {
            firebase.auth.onAuthStateChanged(async (user) => {
                try {
                    if (!user) {
                        history.push('/login');
                        return;
                    }
                    setLoading(false);
                    const idToken = await firebase.auth.currentUser.getIdToken(true);
                    // const [proData, premiumData, subnetData] = await Promise.all([getServerData(idToken, 'pro'), getServerData(idToken, 'premium'), getSubnetCountries(idToken)]);
                    const [proData, premiumData] = await Promise.all([getServerData(idToken, 'pro'), getServerData(idToken, 'premium')]);

                    let providers = (await firebase.db.ref(`providers`).once('value')).val();
                    providers = Object.values(providers || {});

                    let webhooks = (await firebase.db.ref(`webhooks/url`).once('value')).val();
                    setWebhookUrl(webhooks || '');

                    setProServers(proData.servers || []);
                    setPremiumServers(premiumData.servers || []);
                    setProPools(proData.countries || []);
                    setPremiumPools(premiumData.countries || []);
                    // setSubnetCountries(subnetData.countries || []);
                    // setSubnetNocCountries(subnetNocData.countries || []);
                    setProviders(providers);

                    setPending(false);
                } catch (error) {
                    console.log(error);
                    showToastAlert('Error', error.message);
                    setLoading(false);
                    setPending(false);
                }
            });
        })();
    }, [history, firebase.auth]);

    return (
        <>
            {loading && <PageLoader />}
            <div id="page-content-wrapper" className="extra-p">
                <Navbar toggleSideMenu={props.toggleSideMenu} refreshList={refreshList} />

                <div className="container mb-2">
                    <div className="row">
                        <div className="col-12 col-lg-6 dash-head my-0">
                            <div className='plan-nav'>
                                <ul className="nav nav-pills mt-0 mb-4 px-0" id="pills-tab" role="tablist">
                                    {
                                        Object.keys(tabNames).map(key => {
                                            return (
                                                <li className="nav-item" role="presentation" key={key}>
                                                    <button key={key + '-btn'} className={`nav-link px-4 ${activeTabKey === key ? 'active' : ''}`} onClick={changeActiveTab} data-key={key} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{tabNames[key]}</button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 my-0">
                            <div className="search-box"> <input className="search" type="text" placeholder='Provider expiration discord webhook url' value={webhookUrl} onKeyUp={e => webhookKeyEvent(e)} onMouseLeave={e => webhookFocusEvent(e)} onChange={e => setWebhookUrl(e.target.value)} /></div>
                        </div>
                        <div className='col-12 mt-4 mb-2'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Servers</h4>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterServerText} onChange={e => setFilterServerText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={serverColumns} data={filteredServers()} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-4 mb-2'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Providers</h4>
                                            <button className='icon-btn add' onClick={e => handleAddProvider()}><AddCircleOutline /></button>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterProviderText} onChange={e => setFilterProviderText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={providerColumns(handleProviderUpdateClick, handleProviderDeleteClick)} data={filteredProvider()} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-4 mb-2'>
                            <div className="dash-box position-relative">
                                <div className="row" style={{ 'paddingTop': '30px' }}>
                                    <div className="col-12 dash-head my-0">
                                        <div className='d-flex' style={{ 'gap': '20px' }}>
                                            <h4 className="my-0" style={{ 'color': 'var(--main-blue)' }}>Pools</h4>
                                            <button className='icon-btn add' onClick={e => handleAddPool()}><AddCircleOutline /></button>
                                            <button className='icon-btn add' onClick={e => handleModifyPoolOrder()}><ReorderOutlined /></button>
                                        </div>
                                        <div className="search-box"> <input className="search" type="text" placeholder='Search Record...' value={filterPoolText} onChange={e => setFilterPoolText(e.target.value)} /><img src={require("assets/images/search.svg")} alt="" /></div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="table-responsive">
                                            <DataTable columns={poolColumns(handlePoolUpdateClick, handlePoolDeleteClick)} data={filteredPools()} persistTableHead pagination paginationRowsPerPageOptions={[10, 20, 50, 100]} paginationComponentOptions={{ rowsPerPageText: 'Show entries' }} progressPending={pending} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modifyModals.map(modal => (modal))}
        </>
    )
}

export default Plans;