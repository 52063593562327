import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import * as request from 'request';
import { showToastAlert } from '../Services/Utility';


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "white"
    }),
    noOptionsMessage: (provided, state) => ({
        ...provided,
        backgroundColor: "white",
        borderRadius: '5px',
        border: "2px solid lightgray"
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: "white",
        borderRadius: '5px',
        border: "2px solid lightgray"
    }),
}

class ProviderModifyModal extends Component {
    constructor(props) {
        super(props);

        this.isUpdate = props.isUpdate || false;
        this.providerData = props.isUpdate ? props.data : null;
        this.providers = [['Brightdata', 'brightdata'], ['Packet Stream', 'packet'], ['Oxylabs', 'oxylabs'], ['Smart Proxy', 'smart'], ['Netnut', 'netnut'], ['Geosurf', 'geosurf'], ['Iproyal', 'iproyal'], ['One Stop', 'onestop']].map(x => ({ 'label': x[0], 'value': x[1] }));;

        this.state = {
            showModal: props.showModal || false,
            username: this.isUpdate ? this.providerData.username : '',
            password: this.isUpdate ? this.providerData.password : '',
            zone: this.isUpdate ? (this.providerData.zone || '') : '',
            selectedProviders: null
        };
        if (this.isUpdate) {
            this.state.selectedProviders = this.providers.find(x => x.value === this.providerData.id);
        }
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    onChange = (e) => {
        e.persist();
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleProvidersChange = (selectedOption) => {
        this.setState({ ['selectedProviders']: selectedOption || null })
    };

    cancelClicked = async (event) => {
        this.closeModal();
    }

    saveClicked = async (event) => {

        if (this.state.selectedProviders === null) {
            return showToastAlert('Error', 'Select provider');
        }
        if (this.state.username.trim() === '') {
            return showToastAlert('Error', 'Enter username');
        }
        if (this.state.password.trim() === '') {
            return showToastAlert('Error', 'Enter password');
        }
        if (this.state.selectedProviders.value === 'brightdata') {
            if (this.state.zone.trim() === '') {
                return showToastAlert('Error', 'Enter zone');
            }
        }

        let dict = {
            'id': this.state.selectedProviders.value,
            'name': this.state.selectedProviders.label,
            'username': this.state.username.trim(),
            'password': this.state.password.trim()
        }
        if (this.state.selectedProviders.value === 'brightdata') {
            dict['zone'] = this.state.zone.trim();
        }

        await this.props.firebase.db.ref(`providers/${this.state.selectedProviders.value}`).update(dict);
        this.props.successCallback();
        this.closeModal();
    }

    render() {
        const { showModal } = this.state;
        return (
            <Modal
                isOpen={showModal}
                toggle={this.closeModal}
                onClosed={this.closeModal}
                className=""
                size="md"
                backdrop="static"
                centered
            >
                <div className="modal-body">
                    <div className="pop-bg-img">
                        <img src={require("assets/images/pop-bg.svg")} alt="" />
                    </div>
                    <button
                        type="button"
                        className="btn-close pop-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeModal}
                    />
                    <div className="dash-head">
                        <img src={require("assets/images/proxy-gen.svg")} alt="" /> <h4 className="mb-0">{this.isUpdate ? 'Update Provider' : 'Create Provider'}</h4>
                    </div>

                    <div className="form-field px-2">
                        <div className="form-group row mb-2 d-flex align-items-center">
                            <label htmlFor="providers" className="col-sm-5 col-form-label py-0">Providers <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <Select className="select" styles={customStyles} id="providers" onChange={this.isUpdate ? null : this.handleProvidersChange} value={this.state.selectedProviders} placeholder="Select provider" options={this.providers} isDisabled={this.isUpdate} />
                            </div>
                        </div>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="username" className="col-sm-5 col-form-label py-0">Username <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" id="username" onChange={this.onChange} value={this.state.username} placeholder='Enter username' required />
                            </div>
                        </div>
                        <div className="form-group row mb-3 d-flex align-items-center">
                            <label htmlFor="password" className="col-sm-5 col-form-label py-0">Password <span className='text-danger'>*</span></label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" id="password" onChange={this.onChange} value={this.state.password} placeholder='Enter password' required />
                            </div>
                        </div>
                        {
                            this.state.selectedProviders !== null && this.state.selectedProviders.value === 'brightdata' &&
                            <div className="form-group row mb-3 d-flex align-items-center">
                                <label htmlFor="zone" className="col-sm-5 col-form-label py-0">Zone <span className='text-danger'>*</span></label>
                                <div className="col-sm-7">
                                    <input type="text" className="form-control" id="zone" onChange={this.onChange} value={this.state.zone} placeholder='Enter zone' required />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-sm-12 text-end">
                                <div className="d-inline-block mt-4 me-2">
                                    <a href="#" className="mb-3 px-5 cta-white" onClick={this.cancelClicked}>Cancel</a>
                                </div>
                                <div className="form-cta-btn d-inline-block mt-4">
                                    <a href="#" className="mb-3 px-5 text-light" style={{ 'boxShadow': '0px 6px 8px 3px #2c3d7926' }} onClick={this.saveClicked}>{this.isUpdate ? 'Update' : 'Create'}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default withRouter(ProviderModifyModal);